.privacyPolicy {
    margin-top: 30px !important;
    margin-bottom: 120px !important;
}

.titleBlock {
    display: flex;
    align-items: center;
}

.image {
    margin-right: 15px;
}

.title {
    color: var(--text-black, #000);

    /* Text/Headline 24pt */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.text {
    color: var(--text-black, #000);

    /* Text medium 18pt 140% */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 25.2px */
    margin: 30px 0;
}

.underline {
    color: var(--Blue, #1400FF);
}

.singleTitle {
    max-width: 1107px;
    margin: 0 auto;
}

.privacyPolicyContainer {
    max-width: 1134px;
}

.privacyPolicyContainer img {
    margin-right: 15px;
}

.block {
    display: flex;
    align-items: flex-start;
    margin: 30px 0;
}

.block .title {
    margin-bottom: 30px;
}

.block p {
    margin: 0;
    color: var(--text-black, #000);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.strong {
    font-weight: 600;
    color: var(--text-black, #000);

    /* Headline 2 18pt, button text */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
}