.item {
    position: relative;
    width: 22%;
    margin-bottom: 50px;
    transition: 0.3 ease;
}

.item img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: scale-down;
    border-radius: 10px;
}

@media screen and (max-width: 760px) {
    .item {
        width: 47%;
    }
}

@media screen and (max-width: 414px) {
    .item {
        width: 97%;
    }
}