.nav {
    display: flex;
    flex-wrap: wrap;
    color: var(--text-black, #000);
    font-size: 18px;
    font-weight: 500;
    gap: 9.3%;
    margin-top: 63px !important;
    margin-bottom: 63px !important;
}

@media screen and (max-width: 831px) {
    .nav {
        gap: 8%;
    }
}

@media screen and (max-width: 670px) {
    .nav {
        margin-bottom: 40px !important;
    }
}

.item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    width: 15%;
    transition: 0.3s ease;
}

.item:hover, .item:hover strong {
    color: var(--Bright-red, #FF0018);
}

.item:active, .item:active strong {
    color: var(--Dark-Red, #CA1527);
}

.item:nth-child(4n) {
    width: 27%;
}

.item strong {
    color: var(--red-buttons-and-text, #E51428);
}

.item img {
    margin-right: 15px;
}

@media screen and (max-width: 830px) {
    .item, .item:nth-child(4n) {
        width: 28%;
    }
}

@media screen and (max-width: 670px) {
    .item, .item:nth-child(4n) {
        width: 48%;
    }
}

@media screen and (max-width: 414px) {
    .item, .item:nth-child(4n) {
        width: 100%;
    }
}