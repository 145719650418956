.slider {
    width: 100%;
    position: relative;
}

.image {
    aspect-ratio: 1;
    width: 100%;
    object-fit: scale-down;
    user-select: none;
}
