.card {
    border-radius: 10px;
    border: 2px solid rgba(218, 211, 211, 0.40);

    /* Drop shadow blur */
    box-shadow: 0px 2px 8px 2px #C4C4C4;
    width: 29.6%;
    margin-bottom: 45px;
    cursor: pointer;
}

.card img {
    aspect-ratio: 350/212;
    object-fit: scale-down;
    width: 100%;
}

.card h4 {
    color: var(--Black, #000);
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 0;
    padding-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    display: -webkit-box;
    line-clamp: 4;
    line-height: 1.5;
    box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

@media screen and (max-width: 866px) {
    .card {
        width: 29.5%;
    }
    .card h4 {
        font-size: 24px;
    }
}

@media screen and (max-width: 711px) {
    .card {
        width: 29.45%;
    }
}

@media screen and (max-width: 700px) {
    .card {
        width: 45%;
    }
}

@media screen and (max-width: 455px) {
    .card h4 {
        font-size: 18px;
    }
}

@media screen and (max-width: 414px) {
    .card {
        width: 90%;
    }
}