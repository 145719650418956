.filterWrapper {
    position: relative;
    margin-left: 30px;
}

.filterItem {
    border-radius: 10px;
    background: #F0EFEF;
    box-shadow: 0 1px 5px 1px #D3D3D3;
    width: 150px;
    padding: 10px 5px;
    outline: none;
    border: none;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filterItemActive {
    background: #AFACAC66 !important;
}

.filterItem h5 {
    margin: 0 10px 0 0;
    color: var(--text-grey, #505050);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
}

.filterItem:hover, .filterItem:active {
    background: #AFACAC66;
}

.filterImage {
    transform: rotate(180deg);
}

.filterBlock {
    width: 247px;
    min-height: 100px;
    max-height: 447px;
    background-color: #F4F4F4;
    margin: 33px auto; /* для центрирования */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 40px;
    border-radius: 10px;
    z-index: 5;
}

.triangle {
    position: absolute;
    width: 0;
    height: 0;
    top: 45px;
    left: 50%;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 30.6px solid #F4F4F4; /* Высота треугольника - 86.6px (половина от ширины) */
    transform: translateX(-50%);
}

@media screen and (max-width: 915px) {
    .filterWrapper {
        margin-left: 0;
        width: 100%;
    }

    .filterItem {
        width: 80%;
        margin: 20px auto;
    }

    .filterBlock {
        margin: 11px auto;
    }

    .triangle {
        top: 63px;
    }
}

@media screen and (max-width: 414px) {
    .filterBlock {
        width: 230px;
    }
}



