.popularCategories {
    margin-bottom: 75px !important;
    margin-top: 120px !important;
}

@media screen and (max-width: 670px) {
    .popularCategories {
        margin-bottom: 30px !important;
        margin-top: 50px !important;
    }
}

.title {
    margin-bottom: 40px;
    margin-top: 0;
    color: var(--text-black, #000);
    font-size: 24px;
    font-weight: 600;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 4.947%;
}

@media screen and (max-width: 700px) {
    .categories {
        gap: 8.8%;
    }
}

@media screen and (max-width: 641px) {
    .categories {
        gap: 8.5%;
    }
}

@media screen and (max-width: 519px) {
    .categories {
        gap: 8.2%;
    }
}

@media screen and (max-width: 439px) {
    .categories {
        gap: 8%;
    }
}

@media screen and (max-width: 414px) {
    .categories {
        justify-content: center;
    }
}