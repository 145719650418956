.card {
    border-radius: 10px;
    background: var(--objects-and-shapes-white, #FFF);
    width: 236px;
    box-shadow: 0px 2px 8px 2px #C4C4C4;
    text-align: center;
    user-select: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    margin-bottom: 15px;
}

.card h4 {
    margin-bottom: 10px;
    margin-top: 0;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
}

.card p {
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
}

.card p strong {
    font-weight: 700;
}

.card button {
    margin-top: 15px;
    display: block;
    box-sizing: border-box;
    border-radius: 10px;
    background: var(--Red-buttons-and-text, #E51428);
    width: 100%;
    color: #FFF;
    padding: 9px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 0.3s ease;
    border: none;
    outline: none;
}

.card:hover button {
    background: var(--Bright-red, #FF0018);
}

.card:active button {
    background: var(--Dark-Red, #CA1527);
    color: #ffffff;
}