.title {
    color: var(--text-black, #000);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 30px 0;
}

.item {
    display: flex;
    margin: 30px 0;
    color: var(--text-black, #000);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    white-space: pre-wrap;
}

.item img {
    margin-right: 30px;
    width: 56px;
    height: 56px;
}

.item strong {
    color: var(--text-black, #000);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.item span {
    color: var(--Red-buttons-and-text, #E51428);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.item ol, .item ul {
    margin: 0;
}

.item ol {
    padding-left: 23px;
}

.item ul {
    padding-left: 30px;
}

.paymentItem {
    align-items: center;
}