.card {
    display: flex;
    width: 17%;
    max-width: 208px;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid #F5EAEA;
    background: var(--objects-and-shapes-white, #FFF);
    margin-bottom: 40px;
    text-align: center;
    justify-content: space-between;
    transition: 0.3s ease;
}

.card:hover .button {
    background: var(--Bright-red, #FF0018);
}

.card:active .button {
    background: var(--Dark-Red, #CA1527);
    color: #ffffff;
}

.image {
    aspect-ratio: 1;
    object-fit: scale-down;
    margin-bottom: 15px;
}

.name {
    color: var(--text-black, #000);
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
}

.text {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
}

.button {
    margin-top: 15px;
    border-radius: 10px;
    background: var(--Red-buttons-and-text, #E51428);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    color: var(--White, #FFF);
    padding: 9px 13px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 0.3s ease;
    outline: none;
    border: none;
}

.price {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

@media screen and (max-width: 800px) {
    .card {
        width: 22%;
    }
}

@media screen and (max-width: 641px) {
    .card {
        width: 30%;
    }
}

@media screen and (max-width: 530px) {
    .card {
        width: 44%;
        max-width: 45%;
    }
}

@media screen and (max-width: 414px) {
    .card {
        width: 80%;
        max-width: 80%;
    }
}