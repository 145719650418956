.page {
    margin: 30px auto 120px!important;

}

.buttonArticle {
    margin-top: 60px;
}

.header {
    display: flex;
    align-items: center;
    margin: 30px 0 20px;
}

.header img {
    margin-right: 15px;
}

.headerTitle {
    color: #000;
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title {
    color: #000;
    margin: 0 0 24px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.item .image {
    width: 14%;
    margin-right: 10px;
}

.item .image img {
    max-width: 100%;
}

.text {
    width: 86%;
}

.text h4 {
    margin: 0;
    color: var(--text-black, #000);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
}

.text p {
    margin: 0;
    color: var(--text-black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.text span {
    color: var(--Red-buttons-and-text, #E51428);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.text strong {
    color: var(--text-black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.text ul {
    margin: 0;
    padding-inline-start: 28px;
}

.text a {
    color: var(--Blue, #1400FF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.buttonTitle {
    color: var(--text-black, #000);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 40px;
}

.button {
    border-radius: 20px;
    background: var(--Red-buttons-and-text, #E51428);

    /* Drop shadow default */
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    color: var(--White, #FFF);

    /* Button text big */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 31.2px */
    padding: 15px 34px;
    outline: none;
    border: none;
    transition: 0.3s ease;
}

.button:hover {
    background: var(--Bright-red, #FF0018);
}

.button:active {
    background: var(--Dark-Red, #CA1527);
}