.header {
    margin: 30px auto 20px !important;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 915px) {
    .header {
        flex-direction: column;
    }
}

.header h2 {
    color: var(--text-black, #000);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.gallery {
    margin: 20px 0;
    color: #FF0000;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

.emptyBlock {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    color: var(--text-black, #000);

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.items {
    margin-top: 20px;
    display: flex;
    gap: 3.3%;
    flex-wrap: wrap;
}

@media screen and (max-width: 1041px) {
    .items {
        gap: 3.2%;
    }
}

@media screen and (max-width: 861px) {
    .items {
        gap: 3.1%;
    }
}

@media screen and (max-width: 800px) {
    .items {
        gap: 3.2%;
    }
}

@media screen and (max-width: 641px) {
    .items {
        gap: 3.9%;
    }
}

@media screen and (max-width: 530px) {
    .items {
        gap: 10%;
    }
}

@media screen and (max-width: 414px) {
    .items {
        gap: 0;
        align-items: center;
        justify-content: center;
    }
}