.orderProduction {
    margin-bottom: 120px !important;
    margin-top: 30px !important;
}

.titleBlock {
    display: flex;
    align-items: center;
}

.titleBlock img {
    margin-right: 15px;
}

.title {
    color: var(--text-black, #000);
    margin: 0;
    /* Text/Headline 24pt */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.text {
    color: var(--text-black, #000);

    /* Text regular 18pt */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 30px 0 60px
}

.strong {
    color: var(--text-black, #000);

    /* Text medium 18pt */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.button {
    border-radius: 20px;
    background: var(--Red-buttons-and-text, #E51428);

    /* Drop shadow default */
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    color: var(--White, #FFF);

    /* Button text big */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 31.2px */
    margin-top: 40px;
    padding: 15px 34px;
    outline: none;
    border: none;
    transition: 0.3s ease;
}

.button:hover {
    background: var(--Bright-red, #FF0018);
}

.button:active {
    background: var(--Dark-Red, #CA1527);
}